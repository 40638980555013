(function () {
	'use strict';
	angular
		.module('calendar')
		.factory('fullCalendarBridge', [fullCalendarBridge]);

	function fullCalendarBridge() {
		return {
			getDate: getDate,
			gotoDate: gotoDate,
			update: update,
			removeEvents: removeEvents,
			renderEvent: renderEvent,
			addSource: addSource,
			removeSource: removeSource,
		};

		function getDate(calendar) {
			return calendar.fullCalendar('getDate');
		}

		function gotoDate(calendar, date) {
			calendar.fullCalendar('gotoDate', date);
		}

		function update(calendar, event) {
			//Update full calendar view to show event change
			calendar.fullCalendar('updateEvent', event);
		}

		function removeEvents(calendar, event) {
			calendar.fullCalendar('removeEvents', event);
		}

		function renderEvent(calendar, event) {
			calendar.fullCalendar('renderEvent', event, true);
		}

		function addSource(calendar, source) {
			calendar.fullCalendar('addEventSource', source);
		}

		function removeSource(calendar, source, scheduleID) {
			calendar.fullCalendar('removeEventSource', source, scheduleID);
		}
	}
})();
